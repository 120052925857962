import { useCallback, useContext, useMemo } from 'react';
import { Notes, Edit } from '@mui/icons-material';
import { cx } from '@utils';
import { path } from '@consts';
import { Tagging as TaggingIcon } from '@/components/icons';
import { TaggingContext } from '@/components/Conference.Tagging/Context';
import { Tooltip } from '@/presentation/Tooltip';
import { PanelToolsContext } from './Context';
import styles from './style/Panel.Toolbar.css';

type Props = unknown;

export const Toolbar = (props: Props) => {
  const { notes, tagging } = useContext(PanelToolsContext);
  const ctx = useContext(TaggingContext);

  const count = useMemo(() => {
    const active = ctx.items.filter(x => !!x.start);

    return active.length;
  }, [ctx.items]);

  if (!notes?.enabled && !tagging?.enabled) return null;

  return (
    <div className={styles.root}>
      {tagging?.enabled &&
        <TaggingToggle
          count={count}
          onClick={tagging.toggle}
          visible={tagging.visible} />}
      {notes?.enabled &&
        <NotesToggle
          onClick={notes.toggle}
          visible={notes.visible} />}
    </div>
  );
};

Toolbar.displayName = 'Panel.Toolbar';

type NotesToggleProps = {
  onClick: () => void;
  visible: boolean;
};

const NotesToggle = (props: NotesToggleProps) => {
  return (
    <div onClick={props.onClick}>
      <div className={cx(styles.icon, { [styles.open]: props.visible })}>
        <Notes className={styles.notes} />
        <Edit className={styles.edit} />
      </div>
    </div>
  );
};

NotesToggle.displayName = 'Panel.Toolbar.NotesToggle';

type TaggingProps = {
  count: number;
  onClick: () => void;
  visible: boolean;
};

export const TaggingToggle = (props: TaggingProps) => {
  const { tagging } = useContext(PanelToolsContext);

  const title = useMemo(() => tagging.disallowed ? <TooltipContent /> : null, [tagging.disallowed]);
  const disallowedAction = useCallback(() => {
    window.open(path.Website.EnterpriseLogin, '_blank');
  }, []);

  return (
    <Tooltip title={title}>
      <div
        className={cx(styles.icon, { [styles.open]: props.visible, [styles.notAllowed]: tagging.disallowed })}
        onClick={tagging.disallowed ? disallowedAction : props.onClick}>
        <TaggingIcon
          className={cx(styles.tagging, { [styles.active]: props.count > 0 })}
          size={28} />
        {props.count > 0 &&
          <div className={styles.badge}>{props.count}</div>}
      </div>
    </Tooltip>
  );
};

const TooltipContent = () => {
  return (
    <div>
      <a
        href={path.Website.EnterpriseLogin}
        target='_blank'
        rel='noreferrer'>
        <div>You must be logged in to use tagging.</div>
        <div>Try going home to log in and then come back.
        </div>
      </a>
    </div>
  );
};

TaggingToggle.displayName = 'Panel.Toolbar.TaggingToggle';