import { useMemo } from 'react';
import type { IConference } from '@containers/Conference';
import { useCoordinator, useConferenceInstance, useLiveRoomScreen, useCanEnd } from '@containers/Conference';
import { cx, useLockScreen, useToggle } from '@utils';
import type { ConferenceStatus } from '@/types';
import type { RouteLeavingGuardState } from '@/components/RouteLeavingGuard';
import { RouteLeavingGuard } from '@/components/RouteLeavingGuard';
import { ConferenceLeave } from '@/components/Modal/Conference.Leave';
import * as Panel from '@/components/Conference.Panel';
import { TaggingContainer } from '@/components/Conference.Tagging';
import { BarLeft, BarRight } from './Bar';
import { Controls } from './Controls';
import { RecordingStatus } from './RecordingStatus';
import { Session } from './Session';
import { useHasAPIAccess, useYourParticipant } from './hooks';
import styles from './style/ConferenceRoomVideo.css';

type Instance =
  | IConference.Coordinator.Conference.MeetingRoom
  | IConference.Coordinator.Conference.Left
  & Pick<IConference.Coordinator.Conference.BaseRoomProps, 'call' | 'features'>
  & Pick<IConference.Coordinator.Conference.MeetingRoom, 'conference' | 'conferenceIdentifier'>
  & { status: ConferenceStatus };

export function ConferenceRoomVideo() {
  const screen = useLiveRoomScreen();
  const instance = useConferenceInstance<Instance>();
  const { leave } = useCoordinator();
  const you = useYourParticipant();
  const hasAPIAccess = useHasAPIAccess();
  const [showNotes, toggleNotes] = useToggle(hasAPIAccess);
  const [showTags, toggleTags] = useToggle(hasAPIAccess);

  const canEnd = useCanEnd();

  useLockScreen();

  const paneltools = useMemo(() => {
    return {
      notes: {
        toggle: toggleNotes,
        visible: showNotes,
      },
      tagging: {
        toggle: toggleTags,
        visible: showTags,
      },
    };
  }, [
    showNotes,
    showTags,
    toggleNotes,
    toggleTags,
  ]);

  const classnames = useMemo(() => cx({
    [styles.root]: !screen.isFullscreen,
    [styles.rootFs]: screen.isFullscreen,
  }), [screen.isFullscreen]);

  return (
    <div
      className={classnames}
      ref={screen.containerRef}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <BarLeft />
          <BarRight />
        </div>
        <TaggingContainer instance={instance} enabled={instance.features.tags}>
          <Panel.Container
            callId={instance.call.id}
            instance={instance}
            notes={paneltools.notes}
            tagging={paneltools.tagging}>
            <div className={styles.right}>
              <div className={styles.top}>
                <Session />
                <Panel.Sidebar
                  callId={instance.call.id}
                  status={instance.status} />
              </div>
              <div className={styles.bottom}>
                <div className={styles.controls}>
                  <RecordingStatus />

                  <div className={styles.interactive}>
                    <Controls />
                  </div>
                </div>

                <Panel.Toolbar />
              </div>
            </div>
          </Panel.Container>
        </TaggingContainer>
      </div>
      {/* todo: move this to a parent so it won't be destroyed on status change (to fix confirmNavigation) */}
      <RouteLeavingGuard block={instance?.status !== 'left'}>
        {(guard: RouteLeavingGuardState) => (
          <ConferenceLeave
            isHost={you?.isHost}
            canEnd={canEnd}
            open={guard.open}
            onClose={guard.closePrompt}
            onConfirm={(end: boolean) => {
              guard.closePrompt();
              leave({
                conferenceIdentifier: instance.conferenceIdentifier,
                end,
                title: instance.status === 'meeting-room' ? instance.conference.title : null,
                location: guard.pendingLocation.current,
                redirect: 'location',
                participant: instance.participant,
              });
            }} />
        )}
      </RouteLeavingGuard>
    </div>
  );
}