import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { path } from '@consts';
import { Button } from '@/components/Button';
import { getResponseState } from '@/services/api/surveys';
import { Spinner } from '@/components/ActivityIndicator';
import styles from './style/Survey.Preview.css';

type Props = {
  onRetry: () => void;
  isDisqualified: boolean;
  responseIdentifier: string;
};

export const SurveyVersionPreviewComplete = ({ onRetry, isDisqualified, responseIdentifier }: Props) => {
  const displayText = isDisqualified ? 'Your answers resulted in you being disqualified from the survey.' : 'You have completed the survey!';

  return (
    <div className={styles.root}>
      <div className={styles.header}>{displayText}</div>
      <div className={styles.profile}>
        <Button variant={'brick'} onClick={onRetry}>Retry</Button>
      </div>
      {/*<ResponseDebug responseIdentifier={responseIdentifier} />*/}
      <div className={styles.dashboard}>
        <Link to={path.Projects.Root}>Go to Dashboard</Link>
      </div>
    </div>
  );
};

const ResponseDebug = ({ responseIdentifier }: Pick<Props, 'responseIdentifier'>) => {
  const responseQuery = useQuery({
    queryKey: ['survey-response-state', responseIdentifier],
    queryFn: () => getResponseState({ identifier: responseIdentifier }),
  });

  if (responseQuery.isLoading) {
    return <Spinner />;
  } else if (responseQuery.isError) {
    return <div>Error</div>;
  } else {
    return <div>{JSON.stringify(responseQuery.data.response)}</div>;
  }
};

SurveyVersionPreviewComplete.displayName = 'Survey.Version.Preview.Complete';